class AccountHelper {
    static accountNameIsAvailable = (accountInfo, accountName) => {
        if (accountName.trim()) {
            const isValidName = accountName.match(/^[\p{L}\p{N}\p{M}\s-]+$/u);
            if (isValidName) {
                const accountNamesNotAvailable = Object.values(accountInfo).map((account) => account.accountName.trim().toLowerCase());
                const isUnique = accountNamesNotAvailable.findIndex((name) => name === accountName.trim().toLowerCase()) === -1;
                if (isUnique) {
                    return { isAvailable: true, errorMessage: "" };
                } else {
                    return { isAvailable: false, errorMessage: "This name is already being used." };
                }
            } else {
                return { isAvailable: false, errorMessage: "Please only provide alphanumeric characters." };
            }
        } else {
            return { isAvailable: false, errorMessage: "Please don't leave this empty." };
        }
    };

    static checkHasRegisteredUsers = (codes) => {
        return codes && codes.findIndex((code) => code.userMail) !== -1;
    };
}

export default AccountHelper;
