import React from "react";
import { Button } from "@mui/material";
import ForwardTwoToneIcon from "@mui/icons-material/ForwardTwoTone";

function ExitButton({ onClick }) {
    return (
        <div style={styles.exitContainer}>
            <Button variant={"outlined"} onClick={onClick}>
                <ForwardTwoToneIcon color={"primary"} sx={styles.mirror} />
            </Button>
        </div>
    );
}

const styles = {
    exitContainer: {
        flexDirection: "column",
        alignItems: "flex-start",
        alignSelf: "flex-start",
        marginLeft: "2%",
    },
    mirror: {
        transform: "scaleX(-1)",
    },
};

export default ExitButton;
