import ConfigConstants from "../constants/ConfigConstants";

class DatabaseService {
    static getAccounts = async () => {
        try {
            const response = await fetch(ConfigConstants.LINK_TO_BACKEND + "list");
            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
            }

            const accountsData = await response.json();
            return accountsData;
        } catch (e) {
            console.log("fetch failed with error ", e);
            return {};
        }
    };

    static createAccount = async (newAccount) => {
        try {
            const response = await fetch(ConfigConstants.LINK_TO_BACKEND + "add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newAccount),
            });
            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return false;
            }
            return true;
        } catch (e) {
            console.log("fetch failed with error ", e);
            return false;
        }
    };

    static deleteAccount = async (deletingAccountName) => {
        try {
            const response = await fetch(ConfigConstants.LINK_TO_BACKEND + "delete/" + deletingAccountName);
            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return false;
            }
            return true;
        } catch (e) {
            console.log("fetch failed with error ", e);
            return false;
        }
    };

    // changes account data and the codes that belong to it
    static saveAccountChanges = async (account, updatedAccount) => {
        try {
            //only update what needs to be updated
            let temporaryDetails = {};
            let temporaryCodeDetails = {};

            if (account.schoolName !== updatedAccount.schoolName) {
                temporaryDetails.schoolName = updatedAccount.schoolName;
            }
            if (account.schoolAddress !== updatedAccount.schoolAddress) {
                temporaryDetails.schoolAddress = updatedAccount.schoolAddress;
            }
            if (account.description !== updatedAccount.description) {
                temporaryDetails.description = updatedAccount.description;
            }
            if (account.valid !== updatedAccount.valid) {
                temporaryDetails.valid = updatedAccount.valid;
                temporaryCodeDetails.valid = updatedAccount.valid;
            }
            if (account.validUntil !== updatedAccount.validUntil) {
                temporaryDetails.validUntil = new Date(updatedAccount.validUntil).getTime();
                temporaryCodeDetails.validUntil = new Date(updatedAccount.validUntil).getTime();
            }

            const updatingAccountResponse = await this.updateAccount(account.accountName, temporaryDetails);
            if (!updatingAccountResponse) {
                // updateAccount handles error already
                return false;
            }

            // update codes if necessary
            if (Object.keys(temporaryCodeDetails).length > 0) {
                const updatingCodesResponse = await this.updateCodes(account.accountName, temporaryCodeDetails);

                if (!updatingCodesResponse) {
                    // updateCodes handles error already
                    return false;
                }
            }

            return true;
        } catch (e) {
            console.log("fetch failed with error ", e);
            return false;
        }
    };

    static updateAccount = async (accountName, updates) => {
        try {
            const response = await fetch(ConfigConstants.LINK_TO_BACKEND + "update/" + accountName, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updates),
            });

            if (!response.ok) {
                const message = `An error occurred (updating account): ${response.statusText}`;
                window.alert(message);
                return false;
            }

            return true;
        } catch (e) {
            console.log("fetch failed with error ", e);
            return false;
        }
    };

    static getCodesForAccountFromDB = async (accountName) => {
        try {
            const response = await fetch(ConfigConstants.LINK_TO_CODE_BACKEND + "list/" + accountName);
            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
            }

            const accountCodesData = await response.json();
            if (accountCodesData) {
                return accountCodesData;
            } else {
                return {};
            }
        } catch (e) {
            console.log("fetch failed with error ", e);
            return {};
        }
    };

    static addCode = async (newCode) => {
        try {
            const response = await fetch(ConfigConstants.LINK_TO_CODE_BACKEND + "add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newCode),
            });
            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return false;
            }
            return true;
        } catch (e) {
            console.log("fetch failed with error ", e);
            return false;
        }
    };

    static updateCodes = async (accountName, updates) => {
        try {
            // update each code belonging to a certain account
            const codeResponse = await fetch(ConfigConstants.LINK_TO_CODE_BACKEND + "update/" + accountName, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updates),
            });

            if (!codeResponse.ok) {
                const message = `An error occurred (updating codes): ${codeResponse.statusText}`;
                window.alert(message);
                return false;
            }

            return true;
        } catch (e) {
            console.log("fetch failed with error ", e);
            return false;
        }
    };

    static getWebinars = async () => {
        try {
            const response = await fetch(ConfigConstants.LINK_TO_WEBINARS_BACKEND + "list");
            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
            }

            const webinarsData = await response.json();
            return webinarsData;
        } catch (e) {
            console.log("fetch failed with error ", e);
            return {};
        }
    };

    static createWebinar = async (newWebinar) => {
        try {
            const response = await fetch(ConfigConstants.LINK_TO_WEBINARS_BACKEND + "add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newWebinar),
            });
            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return false;
            }
            return true;
        } catch (e) {
            console.log("fetch failed with error ", e);
            return false;
        }
    };

    static updateWebinar = async (webinarId, updates) => {
        try {
            const response = await fetch(ConfigConstants.LINK_TO_WEBINARS_BACKEND + "update/" + webinarId, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updates),
            });

            if (!response.ok) {
                const message = `An error occurred (updating account): ${response.statusText}`;
                window.alert(message);
                return false;
            }

            return true;
        } catch (e) {
            console.log("fetch failed with error ", e);
            return false;
        }
    };

    static deleteWebinar = async (deletingWebinarId) => {
        try {
            const response = await fetch(ConfigConstants.LINK_TO_WEBINARS_BACKEND + "delete/" + deletingWebinarId);
            if (!response.ok) {
                const message = `An error occurred: ${response.statusText}`;
                window.alert(message);
                return false;
            }
            return true;
        } catch (e) {
            console.log("fetch failed with error ", e);
            return false;
        }
    };
}

export default DatabaseService;
