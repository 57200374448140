import { Snackbar } from "@mui/material";

function SnackbarP6PP({ message, onClose }) {
    return (
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={!!message}
            autoHideDuration={2000}
            onClose={onClose}
            message={message}
        />
    );
}

export default SnackbarP6PP;
