import DatabaseService from "../helpers/DatabaseService";

class CodesHelper {
    static createNewCodeModel = (currentAccountName, valid, validUntil) => {
        const codeName = CodesHelper.createRandomString(12);
        return {
            codeName,
            accountName: currentAccountName,
            valid: valid,
            validUntil: validUntil,
            userMail: "",
        };
    };

    static createRandomString = (length) => {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    };

    static addNewCodesToDB = async (accountName, numberNewCodes, accountValid, validUntil) => {
        for (let i = 0; i < numberNewCodes; i++) {
            const newCode = CodesHelper.createNewCodeModel(accountName, accountValid, validUntil);
            await DatabaseService.addCode(newCode);
        }
    };
}

export default CodesHelper;
