import React from "react";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import de from 'date-fns/locale/de';

function DateTimePickerP6PP({ dateTime, setDateTime, label, styles }) {
    return (
        <div style={styles}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                <DateTimePicker
                    label={label}
                    value={dateTime}
                    onChange={(newValue) => {
                        setDateTime(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} color="primary" />}
                    color={"primary"}
                />
            </LocalizationProvider>
        </div>
    );
}

export default DateTimePickerP6PP;
