const TestConstants = {
    TEST_ACCOUNT: {
        id: "1234ABCD",
        schoolName: "testschool",
        schoolAddress: "teststreet in testcity",
        description: "a very nice testaccount",
        accountName: "testaccount",
        creationDate: 1675423748000,
        valid: true,
        validUntil: 1706959748000,
        codes: [],
    },
    DUMMY_ACCOUNT: {
        id: "",
        schoolName: "",
        schoolAddress: "",
        description: "",
        accountName: "",
        creationDate: 0,
        valid: true,
        validUntil: 0,
        codes: [],
    },
};

export default TestConstants;

