import "./App.css";
import AccountOverview from "./screens/AccountOverview";
import { useState } from "react";
import Login from "./screens/Login";
import AccountDetails from "./screens/AccountDetails";
import Webinars from "./screens/Webinars";
import AccountConstants from "./constants/AccountConstants";
import { Box } from "@mui/material";
import ConfigConstants from "./constants/ConfigConstants";
import DesignConstants from "./constants/DesignConstants";

const fusionAuthAppId = "957bf93f-67dd-4b8f-8433-ee4ec7fabfed";
const fusionAuthAppIdDev = "b633d60b-be46-4cff-b174-fbf5e126007e";

function App() {
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [schoolAccount, setSchoolAccount] = useState(AccountConstants.DUMMY_ACCOUNT);
    const [isWebinarScreen, setIsWebinarScreen] = useState(false);

    return (
        <div className="App" style={{ height: "100vh" }}>
            {ConfigConstants.IS_DEV ? (
                <Box
                    sx={{
                        width: "100%",
                        height: DesignConstants.SPACING.DEV_BOX_HEIGHT,
                        backgroundColor: "#82b1ff",
                    }}
                />
            ) : (
                <div />
            )}
            {userLoggedIn ? (
                isWebinarScreen ? (
                    <Webinars setIsWebinarScreen={setIsWebinarScreen} />
                ) : schoolAccount.accountName === "" ? (
                    <AccountOverview setSchoolAccount={setSchoolAccount} setIsWebinarScreen={setIsWebinarScreen} />
                ) : (
                    <AccountDetails schoolAccount={schoolAccount} setSchoolAccount={setSchoolAccount} />
                )
            ) : (
                <Login setUserLoggedIn={setUserLoggedIn} />
            )}
        </div>
    );
}

export default App;
