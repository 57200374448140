import React from "react";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function DatePickerP6PP({ date, setDate, label, styles }) {
    return (
        <div style={styles}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label={label}
                    minDate={Date.now()}
                    value={date}
                    onChange={(newValue) => {
                        setDate(new Date(newValue).getTime());
                    }}
                    renderInput={(params) => <TextField {...params} color="primary" />}
                    color={"primary"}
                />
            </LocalizationProvider>
        </div>
    );
}

export default DatePickerP6PP;
