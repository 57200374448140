import React from "react";
import { Button, Popover, TextField, Typography } from "@mui/material";
import { useState } from "react";

import CodesHelper from "../helpers/CodesHelper";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";

function AddCodesPopover({ addCodesPopoverOpen, setAddCodesPopoverOpen, currentAccountName, valid, validUntil, updateCodes, anchorEl }) {
    const [numberNewCodes, setNumberNewCodes] = useState(0);

    const addNewCodes = async (currentAccountName, valid, validUntil) => {
        if (numberNewCodes > 0) {
            await CodesHelper.addNewCodesToDB(currentAccountName, numberNewCodes, valid, validUntil)
            await updateCodes();
            setAddCodesPopoverOpen(false);
            setNumberNewCodes(0);
        }
    };

    if (addCodesPopoverOpen) {
        return (
            <Popover
                anchorOrigin={{ horizontal: "center", vertical: "center" }}
                transformOrigin={{ horizontal: "center", vertical: "center" }}
                onClose={() => setAddCodesPopoverOpen(false)}
                anchorEl={anchorEl ?? undefined}
                open={addCodesPopoverOpen}
            >
                <div style={styles.popoverInnerContainer}>
                    <Typography variant="h6" color={"primary"} fontWeight={"bold"}>
                        {'Create new Codes for Account "' + currentAccountName + '"'}
                    </Typography>
                    <div>
                        <TextField
                            type={"number"}
                            label={"Number of New Codes"}
                            value={numberNewCodes}
                            margin={"normal"}
                            onChange={(event) => setNumberNewCodes(event.target.value)}
                        />
                    </div>
                    <Button variant={"outlined"} onClick={() => addNewCodes(currentAccountName, valid, validUntil)} style={styles.marginBottom10}>
                        <AddCircleTwoToneIcon color={"primary"} style={styles.marginRight10} />
                        {"Create Codes"}
                    </Button>
                    <Typography variant="subtitle2" color={"grey"}>
                        {"(Saves automatically)"}
                    </Typography>
                </div>
            </Popover>
        );
    } else {
        return <div />;
    }
}

const styles = {
    popoverInnerContainer: {
        padding: 10,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
    marginRight10: {
        marginRight: 10,
    },
    marginBottom10: {
        marginBottom: 10,
    },
};

export default AddCodesPopover;
