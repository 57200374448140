import React, { useEffect } from "react";
import { Button, Checkbox, FormControlLabel, TextField, Typography, Box, InputAdornment, Alert, Collapse } from "@mui/material";
import { useState } from "react";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";

import AccountConstants from "../constants/AccountConstants";
import DatePickerP6PP from "../components/DatePicker";
import DatabaseService from "../helpers/DatabaseService";
import CodeTable from "../components/CodeTable";
import InfoAlert from "../components/InfoAlert";
import ExitButton from "../components/ExitButton";

import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import AddCodesPopover from "../components/AddCodesPopover";
import AutoAwesomeTwoToneIcon from "@mui/icons-material/AutoAwesomeTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ForwardTwoToneIcon from "@mui/icons-material/ForwardTwoTone";

function AccountDetails({ schoolAccount, setSchoolAccount }) {
    const [accountCodes, setAccountCodes] = useState([]);
    const [showCodes, setShowCodes] = useState(false);
    const [showAddCodes, setShowAddCodes] = useState(false);
    const [addCodesAnchorEl, setAddCodesAnchorEl] = useState(null);
    const [showCodesSavedFeedback, setShowCodesSavedFeedback] = useState(false);

    const [schoolName, setSchoolName] = useState(schoolAccount.schoolName ?? "?");
    const [schoolAddress, setSchoolAddress] = useState(schoolAccount.schoolAddress ?? "?");
    const [description, setDescription] = useState(schoolAccount.description ?? "-");
    const [accountValid, setAccountValid] = useState(schoolAccount.valid);
    const [accountValidUntil, setAccountValidUntil] = useState(schoolAccount.validUntil ?? 0);

    const newAccount = {
        schoolName: schoolName,
        accountName: schoolAccount.accountName,
        description: description,
        schoolAddress: schoolAddress,
        valid: accountValid,
        validUntil: accountValidUntil,
    };

    const valueChanged =
        schoolAccount.schoolName !== schoolName ||
        schoolAccount.schoolAddress !== schoolAddress ||
        schoolAccount.description !== description ||
        schoolAccount.valid !== accountValid ||
        schoolAccount.validUntil !== accountValidUntil;

    const schoolNameError = schoolName === "";
    const schoolAddressError = schoolAddress === "";
    const [overallError, setOverallError] = useState(false);
    const [showAccountSavedFeedback, setShowAccountSavedFeedback] = useState(false);

    const [showNotSavedCodeError, setShowNotSavedCodeError] = useState(false);
    const [showNotSavedExitError, setShowNotSavedExitError] = useState(false);

    useEffect(() => {
        getAndSetAccountCodes();
    }, []);

    useEffect(() => {
        // handles successfull save after not saved error when creating codes
        if (showNotSavedCodeError) {
            addCodes();
        }
    }, [schoolAccount]);

    // short feedback for success
    const showCodeSaved = () => {
        setShowCodesSavedFeedback(true);
        setTimeout(() => {
            setShowCodesSavedFeedback(false);
        }, 5000);
    };

    // short feedback for success
    const showAccountSaveSuccess = () => {
        setShowAccountSavedFeedback(true);
        setTimeout(() => {
            setShowAccountSavedFeedback(false);
        }, 5000);
    };

    const getAndSetAccountCodes = async () => {
        const accountCodesInternal = await DatabaseService.getCodesForAccountFromDB(schoolAccount.accountName);
        const newCodes = accountCodesInternal !== accountCodes;
        setAccountCodes(accountCodesInternal);
        return newCodes;
    };

    const addCodes = () => {
        if (valueChanged) {
            //codes can not be added if account has updates that are not saved -> would create codes with old data
            setShowNotSavedCodeError(true);
            setShowAddCodes(false);
        } else {
            setShowAddCodes(true);
            setShowNotSavedCodeError(false);
        }
    };

    const createNewAccountCodes = async () => {
        const newCodes = await getAndSetAccountCodes();
        if (newCodes) {
            showCodeSaved();
        }
    };

    const saveChanges = async (close) => {
        setOverallError(false);
        if (schoolNameError || schoolAddressError) {
            setOverallError(true);
            //hide popups if necessary
            setShowNotSavedExitError(false);
            setShowNotSavedCodeError(false);
            return false;
        } else {
            // save changes
            const editResponse = await DatabaseService.saveAccountChanges(schoolAccount, newAccount);
            if (editResponse) {
                if (close) {
                    setSchoolAccount(AccountConstants.DUMMY_ACCOUNT);
                } else {
                    setSchoolAccount(newAccount);
                    showAccountSaveSuccess();
                }
                return true;
            } else {
                setOverallError(true);
                return false;
            }
        }
    };

    const handleExit = () => {
        if (valueChanged) {
            setShowNotSavedExitError(true);
        } else {
            setSchoolAccount(AccountConstants.DUMMY_ACCOUNT);
        }
    };

    const renderAddCodesPopup = () => {
        return (
            <div style={styles.addCodesPopupContainer}>
                <AddCodesPopover
                    addCodesPopoverOpen={showAddCodes}
                    setAddCodesPopoverOpen={setShowAddCodes}
                    currentAccountName={schoolAccount.accountName}
                    valid={schoolAccount.valid}
                    validUntil={schoolAccount.validUntil}
                    updateCodes={createNewAccountCodes}
                    anchorEl={addCodesAnchorEl}
                />
            </div>
        );
    };

    const renderBasicAccountInfo = () => {
        return (
            <div style={styles.basicAccountInfoOuterContainer}>
                <div style={styles.accountInfoContainer}>
                    <div style={styles.width100}>
                        <TextField
                            label={"School Name"}
                            value={schoolName}
                            margin={"normal"}
                            onChange={(event) => setSchoolName(event.target.value)}
                            fullWidth
                            error={schoolNameError}
                            helperText={schoolNameError ? "Please don't leave this empty." : ""}
                            InputProps={markChanges(schoolAccount.schoolName !== schoolName)}
                        />
                    </div>
                </div>
                <div style={styles.accountInfoContainer}>
                    <div style={styles.width100}>
                        <TextField
                            label={"School Address"}
                            value={schoolAddress}
                            multiline
                            margin={"normal"}
                            onChange={(event) => setSchoolAddress(event.target.value)}
                            size={"medium"}
                            fullWidth
                            error={schoolAddressError}
                            helperText={schoolAddressError ? "Please don't leave this empty." : ""}
                            InputProps={markChanges(schoolAccount.schoolAddress !== schoolAddress)}
                        />
                    </div>
                </div>
                <div style={styles.accountInfoContainer}>
                    <div style={styles.width100}>
                        <TextField
                            label={"Account Name"}
                            value={schoolAccount.accountName}
                            margin={"normal"}
                            onChange={(event) => {}}
                            fullWidth
                            disabled
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderDescription = () => {
        return (
            <div style={styles.padding10}>
                <TextField
                    label={"Description (optional)"}
                    value={description}
                    margin={"normal"}
                    multiline
                    fullWidth
                    onChange={(event) => setDescription(event.target.value)}
                    helperText={description === "" ? "Leaving this empty will delete the description" : ""}
                    InputProps={markChanges(schoolAccount.description !== description)}
                />
            </div>
        );
    };

    const renderCodeButtons = () => {
        return (
            <div style={styles.codeButtonsContainer}>
                <div style={{ flex: 1, paddingRight: showCodes ? 10 : 0 }}>
                    <Button
                        variant={"outlined"}
                        onClick={() => {
                            setShowCodes(!showCodes);
                        }}
                        fullWidth
                    >
                        {showCodes ? <VisibilityOffTwoToneIcon color={"primary"} /> : <VisibilityTwoToneIcon color={"primary"} />}
                        <div style={styles.marginLeft10}>{showCodes ? "Hide Codes" : "Show Codes"}</div>
                    </Button>
                </div>
                {showCodes ? (
                    <div style={styles.addCodesButtonContainer}>
                        <Button
                            variant={"outlined"}
                            onClick={(event) => {
                                setAddCodesAnchorEl(event.currentTarget);
                                addCodes();
                            }}
                            fullWidth
                        >
                            <AddCircleTwoToneIcon color={"primary"} />
                            <div style={styles.marginLeft10}>{"Add Codes"}</div>
                        </Button>
                    </div>
                ) : (
                    <div />
                )}
            </div>
        );
    };

    const renderCodeAddedFeedback = () => {
        return (
            <Collapse in={showCodesSavedFeedback}>
                <div style={styles.padding10}>
                    <Alert
                        severity="success"
                        iconMapping={{
                            success: <CheckCircleTwoToneIcon fontSize="inherit" />,
                        }}
                    >
                        {"Codes added!"}
                    </Alert>
                </div>
            </Collapse>
        );
    };

    const renderCodes = () => {
        return (
            <Collapse in={showCodes}>
                <Box sx={{ border: 1, borderRadius: 1, overflow: "hidden", margin: "10px", borderColor: "#d4e3fa" }}>
                    <div style={{ maxHeight: 230, overflow: "scroll" }}>
                        <CodeTable accountCodes={accountCodes} />
                    </div>
                </Box>
            </Collapse>
        );
    };

    const renderValidSection = () => {
        return (
            <div style={styles.validityContainer}>
                <div style={styles.validItemContainer}>
                    {schoolAccount.validUntil !== accountValidUntil ? (
                        <AutoAwesomeTwoToneIcon color={"primary"} style={styles.marginright5} />
                    ) : (
                        <div />
                    )}
                    <DatePickerP6PP label={"Valid until"} date={accountValidUntil} setDate={setAccountValidUntil} />
                </div>
                <div style={styles.validItemContainer}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={accountValid}
                                onClick={() => {
                                    setAccountValid(!accountValid);
                                }}
                                defaultChecked={accountValid}
                            />
                        }
                        label={"Account is valid"}
                    />
                    {schoolAccount.valid !== accountValid ? <AutoAwesomeTwoToneIcon color={"primary"} /> : <div />}
                </div>
            </div>
        );
    };

    const renderAlertWhenCreatingCodesButUnsaved = () => {
        return (
            <InfoAlert
                infoTitle={"Unsaved Changes"}
                content={
                    <div style={styles.alertNotSavedContainer}>
                        <Typography variant="subtitle" style={styles.maringRight5}>
                            {"This account has some"}
                        </Typography>
                        <AutoAwesomeTwoToneIcon color={"primary"} />
                        <Typography variant="subtitle" color={"primary"} style={{ marginLeft: 5 }} fontWeight={"bold"}>
                            {"unsaved changes."}
                        </Typography>
                        <Typography variant="subtitle" style={{ marginLeft: 5 }}>
                            {"Please save them before creating new codes."}
                        </Typography>
                    </div>
                }
                show={showNotSavedCodeError}
                setShow={setShowNotSavedCodeError}
                anchorEl={undefined}
                buttonOneText={"Save"}
                buttonOneAction={async () => {
                    const saved = await saveChanges();
                    if (!saved) {
                        // close so user can take a look at possible error messages
                        setShowNotSavedCodeError(false);
                    }
                    // successfull save gets handled by useEffect, because we have to wait for the schoolAccount to update
                }}
                buttonOneIcon={<SaveTwoToneIcon color={"primary"} style={styles.marginRight10} />}
                buttonTwoText={"Cancel"}
                buttonTwoAction={() => {
                    setShowNotSavedCodeError(false);
                }}
                buttonTwoIcon={<CloseTwoToneIcon color={"primary"} style={styles.marginRight10} />}
            />
        );
    };

    const renderAlertWhenExitingButUnsaved = () => {
        return (
            <InfoAlert
                infoTitle={"Unsaved Changes"}
                content={
                    <div style={styles.alertNotSavedContainer}>
                        <Typography variant="subtitle" style={styles.marginright5}>
                            {"This account has some"}
                        </Typography>
                        <AutoAwesomeTwoToneIcon color={"primary"} style={styles.marginright5} />
                        <Typography variant="subtitle" color={"primary"} fontWeight={"bold"}>
                            {"unsaved changes."}
                        </Typography>
                    </div>
                }
                show={showNotSavedExitError}
                setShow={setShowNotSavedExitError}
                anchorEl={undefined}
                buttonOneText={"Save & Exit"}
                buttonOneAction={async () => {
                    // saves and exits
                    saveChanges(true);
                }}
                buttonOneIcon={
                    <div style={styles.alertButtonContainer}>
                        <ForwardTwoToneIcon color={"primary"} sx={{ transform: "scaleX(-1)" }} />
                        <SaveTwoToneIcon color={"primary"} />
                    </div>
                }
                buttonTwoText={"Discard & exit"}
                buttonTwoAction={() => {
                    //closes without saving
                    setSchoolAccount(AccountConstants.DUMMY_ACCOUNT);
                }}
                buttonTwoIcon={
                    <div style={styles.alertButtonContainer}>
                        <ForwardTwoToneIcon color={"primary"} sx={{ transform: "scaleX(-1)" }} />
                        <DeleteTwoToneIcon color={"primary"} />
                    </div>
                }
                buttonThreeText={"Cancel"}
                buttonThreeAction={() => {
                    //just closes popup
                    setShowNotSavedExitError(false);
                }}
                buttonThreeIcon={<CloseTwoToneIcon color={"primary"} style={styles.marginRight10} />}
            />
        );
    };

    const renderAccountSavedFeedback = () => {
        return (
            <Collapse in={showAccountSavedFeedback}>
                <div style={styles.feedbackContainer}>
                    <Alert
                        severity="success"
                        iconMapping={{
                            success: <CheckCircleTwoToneIcon fontSize="inherit" />,
                        }}
                    >
                        {"Account updated!"}
                    </Alert>
                </div>
            </Collapse>
        );
    };

    const renderBottomButtons = () => {
        return (
            <div style={styles.bottomButtonsContainer}>
                <Button
                    variant={"outlined"}
                    onClick={() => {
                        saveChanges(true);
                    }}
                    disabled={!valueChanged}
                    style={styles.marginRight10}
                >
                    <ForwardTwoToneIcon color={"primary"} sx={valueChanged ? styles.mirror : styles.mirrorInGrey} />
                    <SaveTwoToneIcon color={"primary"} sx={valueChanged ? undefined : styles.colorGrey} />
                    <div style={{ marginLeft: 10 }}>{"Save & Exit"}</div>
                </Button>
                <Button
                    variant={"outlined"}
                    onClick={() => {
                        saveChanges();
                    }}
                    disabled={!valueChanged}
                >
                    <SaveTwoToneIcon color={"primary"} sx={valueChanged ? undefined : styles.colorGrey} />
                    <div style={{ marginLeft: 10 }}>{"Save"}</div>
                </Button>
            </div>
        );
    };

    const renderOverallError = () => {
        if (overallError) {
            return (
                <div style={styles.marginBottom10}>
                    <Typography sx={{ color: "red" }} fontWeight={"bold"}>
                        {"An error occured. Please check your inputs"}
                    </Typography>
                </div>
            );
        } else {
            return <div />;
        }
    };

    // helps marking changes with some stars
    const markChanges = (changed: boolean) => {
        if (changed) {
            return {
                endAdornment: (
                    <InputAdornment position="start">
                        <AutoAwesomeTwoToneIcon color={"primary"} />
                    </InputAdornment>
                ),
            };
        } else {
            return undefined;
        }
    };

    return (
        <div style={styles.outerContainer}>
            <ExitButton onClick={handleExit} />
            <Typography variant="h4" color={"primary"} fontWeight={"bold"}>
                {schoolAccount.schoolName ?? ""}
            </Typography>
            <div style={styles.innerContainer}>
                {renderBasicAccountInfo()}
                {renderDescription()}
                {renderCodeButtons()}
                {renderCodeAddedFeedback()}
                {renderCodes()}
                {renderValidSection()}
                {renderAddCodesPopup()}
                {renderAlertWhenCreatingCodesButUnsaved()}
                {renderAlertWhenExitingButUnsaved()}
                {renderAccountSavedFeedback}
            </div>
            {renderOverallError()}
            {renderBottomButtons()}
        </div>
    );
}

const styles = {
    outerContainer: {
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 20,
    },
    innerContainer: {
        padding: 24,
        paddingBottom: 0,
        flexWrap: "wrap",
        borderColor: "primary",
        borderWidth: 2,
        width: "100%",
        maxWidth: 1000,
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
    },
    addCodesPopupContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    exitContainer: {
        flexDirection: "column",
        alignItems: "flex-start",
        alignSelf: "flex-start",
        marginLeft: "2%",
    },
    mirror: {
        transform: "scaleX(-1)",
    },
    mirrorInGrey: {
        color: "grey",
        transform: "scaleX(-1)",
    },
    basicAccountInfoOuterContainer: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    accountInfoContainer: {
        flex: 1,
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        padding: 10,
    },
    width100: {
        width: "100%",
    },
    padding10: {
        padding: 10,
    },
    paddingRight: {
        paddingRight: 10,
    },
    marginLeft10: {
        marginLeft: 10,
    },
    marginright5: {
        marginRight: 5,
    },
    marginRight10: {
        marginRight: 5,
    },
    colorGrey: {
        color: "grey",
    },
    marginBottom10: {
        marginBottom: 10,
    },
    codeButtonsContainer: {
        padding: 10,
        width: "100%",
        alignSelf: "center",
        flexDirection: "row",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    validityContainer: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
        flex: 1,
    },
    validItemContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
    },
    addCodesButtonContainer: {
        flex: 1,
        paddingLeft: 10,
    },
    alertNotSavedContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: 15,
    },
    alertButtonContainer: {
        display: "flex",
        flexDirection: "row",
        marginRight: 10,
    },
    feedbackContainer: {
        padding: 10,
        flex: 1,
    },
    bottomButtonsContainer: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
};

export default AccountDetails;
