import React, { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import DesignConstants from "../constants/DesignConstants";
import SnackbarP6PP from "./Snackbar";

function CodeTable({ accountCodes }) {
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const handleCopyToClipboard = (contentToCopy) => {
        navigator.clipboard.writeText(contentToCopy);
        setSnackbarMessage(`Copied '${contentToCopy}' to clipboard!`);
    };

    const renderCodes = () => {
        if (accountCodes && accountCodes.length > 0) {
            return accountCodes.map((codeInfo, index) => {
                return (
                    <TableRow key={codeInfo.codeName}>
                        <TableCell style={styles.preventUserSelection}>{index + 1}</TableCell>
                        <TableCell style={styles.codeToCopy} onClick={() => handleCopyToClipboard(codeInfo.codeName)}>
                            {codeInfo.codeName}
                        </TableCell>
                        <TableCell
                            style={codeInfo.userMail ? styles.codeToCopy : styles.preventUserSelection}
                            onClick={() => codeInfo.userMail && handleCopyToClipboard(codeInfo.userMail)}
                        >
                            {codeInfo.userMail}
                        </TableCell>
                        <TableCell style={styles.preventUserSelection}>{codeInfo.valid ? "true" : "false"}</TableCell>
                    </TableRow>
                );
            });
        } else {
            return (
                <TableRow>
                    <TableCell style={styles.noCodesTableCell} align="center" colSpan={4}>
                        {"No codes yet"}
                    </TableCell>
                </TableRow>
            );
        }
    };

    return (
        <>
            <Table>
                <TableHead style={styles.tableHead}>
                    <TableRow>
                        <TableCell>{"Nr."}</TableCell>
                        <TableCell>{"Code Name"}</TableCell>
                        <TableCell>{"User Mail"}</TableCell>
                        <TableCell>{"Code is Valid"}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{renderCodes()}</TableBody>
            </Table>
            <SnackbarP6PP message={snackbarMessage} onClose={() => setSnackbarMessage("")} />
        </>
    );
}

const styles = {
    tableHead: {
        backgroundColor: DesignConstants.COLORS.BACKGROUND_BLUE,
    },
    preventUserSelection: {
        userSelect: "none",
    },
    codeToCopy: {
        cursor: "pointer",
    },
    noCodesTableCell: {
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
    },
};

export default CodeTable;
