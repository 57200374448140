import React, { useEffect } from "react";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Popover,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    CircularProgress,
} from "@mui/material";
import { useState } from "react";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import CheckBoxOutlineBlankTwoToneIcon from "@mui/icons-material/CheckBoxOutlineBlankTwoTone";
import { CSVLink } from "react-csv/lib";
import ConfigConstants from "../constants/ConfigConstants";
import DesignConstants from "../constants/DesignConstants";
import DatabaseService from "../helpers/DatabaseService";
import CodesHelper from "../helpers/CodesHelper";
import AccountHelper from "../helpers/AccountHelper";
import DatePickerP6PP from "../components/DatePicker";
import CodeTable from "../components/CodeTable";
import AddCodesPopover from "../components/AddCodesPopover";
import InfoAlert from "../components/InfoAlert";
import SnackbarP6PP from "../components/Snackbar";

const _convertToNonNegativeInteger = (userInput) => {
    if (userInput === "") {
        return 0;
    } else {
        const parsedUserInput = parseInt(userInput, 10);
        return parsedUserInput >= 0 ? parsedUserInput : 0;
    }
};

function AccountOverview({ setSchoolAccount, setIsWebinarScreen }) {
    const [accountInfo, setAccountInfo] = useState({});
    const [accountCodes, setAccountCodes] = useState({});

    const [morePopoverOpen, setMorePopoverOpen] = useState(false);
    const [currentAccountName, setCurrentAccountName] = useState("");

    const [addAccountPopoverOpen, setAddAccountPopoverOpen] = useState(false);
    const [schoolName, setSchoolName] = useState("");
    const [schoolAddress, setSchoolAddress] = useState("");
    const [description, setDescription] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountValid, setAccountValid] = useState(false);
    const [accountValidUntil, setAccountValidUntil] = useState(Date.now());
    const [numberNewCodes, setNumberNewCodes] = useState(0);
    const [accountNameError, setAccountNameError] = useState("");

    const [addCodesPopoverOpen, setAddCodesPopoverOpen] = useState(false);
    const [codesPopoverOpen, setCodesPopoverOpen] = useState(false);

    const [snackbarMessage, setSnackbarMessage] = useState("");

    const [doyouReallyWantDelete, setDoYouReallyWantDelete] = useState(false);
    const [moreAnchorEl, setMoreAddCodesAnchorEl] = useState(null);

    const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);

    const accountRefObject = {};

    const handleAmountNewCodesChange = (userInput) => {
        const result = _convertToNonNegativeInteger(userInput);
        setNumberNewCodes(result);
    };

    const textFieldProps = [
        {
            label: "School Name",
            value: schoolName,
            onChange: setSchoolName,
        },
        {
            label: "School Address",
            value: schoolAddress,
            onChange: setSchoolAddress,
            multiline: true,
        },
        {
            label: "Description (optional)",
            value: description,
            onChange: setDescription,
        },
        {
            label: "Account Name",
            value: accountName,
            onChange: setAccountName,
            error: accountNameError,
            helperText: accountNameError,
            required: true,
        },
        {
            label: "Number of New Codes",
            value: numberNewCodes,
            onChange: handleAmountNewCodesChange,
            type: "number",
        },
    ];

    useEffect(() => {
        getAccountsFromDB();
    }, []);

    useEffect(() => {
        getCodes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo]);

    const getAccountsFromDB = async () => {
        setIsLoadingAccounts(true);
        const accountsData = await DatabaseService.getAccounts();
        if (accountsData) {
            const accountsDataObject = {};
            for (const account of accountsData) {
                accountsDataObject[account.accountName] = account;
            }
            setAccountInfo(accountsDataObject);
        }
        setIsLoadingAccounts(false);
    };

    const getCodes = async () => {
        Object.values(accountInfo).forEach(async (account) => {
            await getCodesForAccountFromDB(account.accountName);
        });
    };

    const getCodesForAccountFromDB = async (accountName) => {
        const accountCodesData = await DatabaseService.getCodesForAccountFromDB(accountName);
        if (accountCodesData) {
            const newAccountCodes = accountCodes;
            newAccountCodes[accountName] = accountCodesData;
            setAccountCodes({ ...newAccountCodes });
        }
    };

    const addNewCodes = async (validUntil) => {
        if (numberNewCodes > 0) {
            await CodesHelper.addNewCodesToDB(accountName, numberNewCodes, accountValid, validUntil);
            setNumberNewCodes(0);
        }
    };

    const createAccount = async () => {
        setAddAccountPopoverOpen(false);
        const validUntil = new Date(accountValidUntil).getTime();
        const newAccount = {
            schoolName,
            schoolAddress,
            description,
            accountName: accountName.trim(),
            creationDate: Date.now(),
            validUntil,
            valid: accountValid,
            codes: [],
        };

        await DatabaseService.createAccount(newAccount);
        await addNewCodes(validUntil);
        await getAccountsFromDB();
    };

    const changeValidity = async (accountName, validity) => {
        try {
            await DatabaseService.updateAccount(accountName, { valid: validity });
            await DatabaseService.updateCodes(accountName, { valid: validity });

            await getAccountsFromDB();
            await getCodesForAccountFromDB(accountName);
        } catch (e) {
            console.log("fetch failed with error ", e);
        }
    };

    const deleteAccount = async () => {
        setMorePopoverOpen(false);
        setDoYouReallyWantDelete(false);
        await DatabaseService.deleteAccount(currentAccountName);
        await getAccountsFromDB();
    };

    const moreButtonClicked = (accountName) => {
        morePopoverOpen ? setCurrentAccountName("") : setCurrentAccountName(accountName);
        setMorePopoverOpen(!morePopoverOpen);
    };

    const resetSchoolStates = () => {
        setSchoolName("");
        setSchoolAddress("");
        setDescription("");
        setAccountName("");
        setNumberNewCodes(0);
        setAccountValid(false);
        setAccountValidUntil(Date.now());
    };

    const handleCreateAccount = async () => {
        const { isAvailable, errorMessage } = AccountHelper.accountNameIsAvailable(accountInfo, accountName);
        if (isAvailable) {
            setAccountNameError("");
            await createAccount();
            resetSchoolStates();
        } else {
            setAccountNameError(errorMessage);
        }
    };

    const handleCopyCodesToClipboard = (codes) => {
        const codeNames = codes && codes.map((codeObj) => codeObj.codeName);
        setSnackbarMessage("Copied all codes to clipboard!");
        navigator.clipboard.writeText(codeNames.join("\n"));
    };

    const renderAccounts = () => {
        return Object.values(accountInfo).map((account) => {
            const accountEntries = [];
            accountRefObject[account.accountName] = React.createRef();
            accountEntries.push(
                <TableRow
                    ref={accountRefObject[account.id]}
                    key={account.accountName?.replace(/ /g, "-")}
                    sx={
                        currentAccountName === account.accountName && morePopoverOpen
                            ? { backgroundColor: DesignConstants.COLORS.BACKGROUND_BRIGHT_BLUE }
                            : {}
                    }
                >
                    <TableCell>
                        {accountCodes[account.accountName] ? (
                            <div style={styles.activityIndicatorContainer}>
                                <div
                                    style={{
                                        ...styles.activityIndicator,
                                        backgroundColor: AccountHelper.checkHasRegisteredUsers(accountCodes[account.accountName])
                                            ? DesignConstants.COLORS.ACCOUNT_ACTIVE
                                            : DesignConstants.COLORS.ACCOUNT_INACTIVE,
                                    }}
                                />
                            </div>
                        ) : (
                            <CircularProgress size={20} />
                        )}
                    </TableCell>
                    <TableCell>{account.accountName}</TableCell>
                    <TableCell>{account.schoolName + " " + account.schoolAddress}</TableCell>
                    <TableCell>{account.description}</TableCell>
                    <TableCell>{new Date(account.validUntil).toDateString()}</TableCell>
                    <TableCell>{account.valid ? "true" : "false"}</TableCell>
                    <TableCell>
                        {accountCodes[account.accountName] ? accountCodes[account.accountName]?.length ?? 0 : <CircularProgress size={20} />}
                    </TableCell>
                    <TableCell>
                        <Button
                            variant={"outlined"}
                            onClick={(event) => {
                                setMoreAddCodesAnchorEl(event.currentTarget);
                                moreButtonClicked(account.accountName, account._id);
                            }}
                        >
                            {"..."}
                        </Button>
                    </TableCell>
                    <TableCell>
                        <Button
                            onClick={() => {
                                setSchoolAccount(account);
                            }}
                        >
                            <EditTwoToneIcon color="primary" />
                        </Button>
                    </TableCell>
                    {renderMorePopover(account.accountName, account.valid)}
                </TableRow>,
            );
            return accountEntries;
        });
    };

    const renderMorePopover = () => {
        if (morePopoverOpen) {
            const currentAccountValid = accountInfo[currentAccountName].valid;
            const validityString = currentAccountValid ? "invalid" : "valid";
            const changeValidityButtonName = "Set to " + validityString;
            const validityIcon = currentAccountValid ? (
                <CheckBoxTwoToneIcon color={"primary"} />
            ) : (
                <CheckBoxOutlineBlankTwoToneIcon color={"primary"} />
            );
            return (
                <Popover
                    anchorOrigin={{ horizontal: "center", vertical: "top" }}
                    transformOrigin={{ horizontal: "center", vertical: "top" }}
                    onClose={() => setMorePopoverOpen(false)}
                    anchorEl={moreAnchorEl}
                    open={morePopoverOpen}
                >
                    <div style={styles.morePopoverContainer}>
                        <div style={styles.moreButtonContainer}>
                            {validityIcon}
                            <Button
                                onClick={() => {
                                    changeValidity(currentAccountName, !currentAccountValid);
                                }}
                            >
                                {changeValidityButtonName}
                            </Button>
                        </div>
                        <div style={styles.moreButtonContainer}>
                            <VisibilityTwoToneIcon color={"primary"} />
                            <Button
                                onClick={() => {
                                    setCodesPopoverOpen(!codesPopoverOpen);
                                }}
                            >
                                {"See Codes"}
                            </Button>
                        </div>
                        <div style={styles.moreButtonContainer}>
                            <AddCircleTwoToneIcon color={"primary"} />
                            <Button
                                onClick={() => {
                                    setAddCodesPopoverOpen(!addCodesPopoverOpen);
                                }}
                            >
                                {"Create Codes"}
                            </Button>
                        </div>
                        <div style={styles.moreButtonContainer}>
                            <DeleteTwoToneIcon color={"primary"} />
                            <Button
                                onClick={() => {
                                    setDoYouReallyWantDelete(true);
                                }}
                            >
                                {"Delete Account"}
                            </Button>
                        </div>
                    </div>
                </Popover>
            );
        }
    };

    const renderAddAccountPopover = () => {
        return (
            <Popover
                anchorOrigin={{ horizontal: "center", vertical: "center" }}
                transformOrigin={{ horizontal: "center", vertical: "center" }}
                anchorEl={undefined}
                onClose={() => {
                    setAddAccountPopoverOpen(false);
                    setAccountNameError("");
                }}
                open={addAccountPopoverOpen}
            >
                <div style={styles.addAccountContainer}>
                    <Typography variant="h4" color={"primary"} style={styles.marginTop20}>
                        {"Create New School Account"}
                    </Typography>
                    <div style={styles.addAccountDataContainer}>
                        {textFieldProps.map((field, index) => (
                            <TextField
                                key={index}
                                label={field.label}
                                value={field.value}
                                onChange={(event) => field.onChange(event.target.value)}
                                margin="normal"
                                style={styles.newAccountTextField}
                                multiline={field.multiline}
                                error={!!field.error}
                                helperText={field.helperText}
                                required={field.required}
                                type={field.type}
                                InputProps={{ inputProps: { min: 0 } }}
                            />
                        ))}
                        {renderDatePicker()}
                        <FormControlLabel
                            control={<Checkbox checked={accountValid} onClick={() => setAccountValid(!accountValid)} />}
                            label={"Account is valid"}
                            style={styles.addAccountValidContainer}
                        />
                    </div>
                    <Button variant={"outlined"} style={styles.marginBottom24} onClick={() => handleCreateAccount()}>
                        <AddCircleTwoToneIcon style={styles.marginRight10} />
                        {"Create Account"}
                    </Button>
                </div>
            </Popover>
        );
    };

    const renderDatePicker = () => {
        return (
            <div style={{ ...styles.newAccountTextField, ...styles.datePickerContainer }}>
                <DatePickerP6PP date={accountValidUntil} setDate={setAccountValidUntil} label={"Valid Until"} />
            </div>
        );
    };

    const renderAddCodesPopover = () => {
        return (
            <AddCodesPopover
                addCodesPopoverOpen={addCodesPopoverOpen}
                setAddCodesPopoverOpen={setAddCodesPopoverOpen}
                currentAccountName={currentAccountName}
                valid={accountInfo[currentAccountName]?.valid ?? false}
                validUntil={accountInfo[currentAccountName]?.validUntil ?? false}
                updateCodes={() => getCodesForAccountFromDB(currentAccountName)}
            />
        );
    };

    const renderCodesPopover = () => {
        if (codesPopoverOpen) {
            return (
                <Popover
                    anchorOrigin={{ horizontal: "center", vertical: "center" }}
                    transformOrigin={{ horizontal: "center", vertical: "center" }}
                    anchorEl={undefined}
                    onClose={() => setCodesPopoverOpen(false)}
                    open={codesPopoverOpen}
                    style={styles.marginTop10}
                >
                    <div style={styles.popoverInnerContainer}>
                        <CodeTable accountCodes={accountCodes[currentAccountName]} />

                        <div style={styles.buttonsContainer}>
                            <div style={styles.downloadCodesContainer}>
                                <CSVLink data={accountCodes[currentAccountName]}>Download Codes</CSVLink>
                            </div>
                            <Button variant="contained" onClick={() => handleCopyCodesToClipboard(accountCodes[currentAccountName])}>
                                Copy All Codes To Clipboard
                            </Button>
                        </div>
                    </div>
                </Popover>
            );
        }
    };

    const renderDeleteConfirmPopup = () => {
        return (
            <InfoAlert
                show={doyouReallyWantDelete}
                setShow={setDoYouReallyWantDelete}
                infoTitle={"Account Deletion"}
                text={"Do you really want to delete the account " + currentAccountName + "?"}
                buttonOneText={"Yes, delete"}
                buttonOneAction={deleteAccount}
                buttonOneIcon={<DeleteTwoToneIcon color={"primary"} style={styles.marginRight10} />}
                buttonTwoText={"Cancel"}
                buttonTwoAction={() => {
                    setDoYouReallyWantDelete(false);
                }}
                buttonTwoIcon={<CloseTwoToneIcon color={"primary"} />}
                onExited={() => {
                    const accountNamePresent = accountInfo.hasOwnProperty(currentAccountName);
                    if (!accountNamePresent) {
                        //meaning the popup is closing after deletion
                        setCurrentAccountName("");
                    }
                }}
            />
        );
    };

    return (
        <div style={styles.container}>
            <Typography variant="h4" color={"primary"}>
                {ConfigConstants.TITLE}
            </Typography>
            <div style={styles.marginTop20}>
                <Button
                    variant={"outlined"}
                    onClick={() => {
                        setAddAccountPopoverOpen(!addAccountPopoverOpen);
                    }}
                >
                    <AddCircleTwoToneIcon />
                    <div style={styles.marginLeft10}>{"Create School Account"}</div>
                </Button>
            </div>
            <div style={styles.marginTop20}>
                <Button variant={"contained"} onClick={() => setIsWebinarScreen(true)}>
                    <div style={styles.marginRight10}>{"Webinars"}</div>
                    <ArrowForwardIcon />
                </Button>
            </div>
            {isLoadingAccounts ? (
                <CircularProgress size={80} sx={{ m: 40 }} />
            ) : (
                <TableContainer style={styles.tableContainer}>
                    <Table stickyHeader>
                        <TableHead
                            sx={{
                                // applies the background color to the material-ui header as the stickyHeader overwrites other css properties
                                "& .MuiTableCell-head": styles.tableHead,
                            }}
                        >
                            <TableRow>
                                <TableCell>{"Active"}</TableCell>
                                <TableCell>{"Account Name"}</TableCell>
                                <TableCell>{"School"}</TableCell>
                                <TableCell>{"Description"}</TableCell>
                                <TableCell>{"Valid Until"}</TableCell>
                                <TableCell>{"Valid"}</TableCell>
                                <TableCell>{"Number of Codes"}</TableCell>
                                <TableCell>{"More"}</TableCell>
                                <TableCell>{"Edit School"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderAccounts()}</TableBody>
                    </Table>
                </TableContainer>
            )}
            {renderAddAccountPopover()}
            {renderAddCodesPopover()}
            {renderCodesPopover()}
            {renderDeleteConfirmPopup()}
            <SnackbarP6PP message={snackbarMessage} onClose={() => setSnackbarMessage("")} />
        </div>
    );
}

const styles = {
    container: {
        paddingTop: DesignConstants.SPACING.PADDING_TOP,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxHeight: `calc(100% - ${(ConfigConstants.IS_DEV ? DesignConstants.SPACING.DEV_BOX_HEIGHT : 0) + DesignConstants.SPACING.PADDING_TOP}px)`,
    },
    marginTop20: {
        marginTop: 20,
    },
    marginRight10: {
        marginRight: 10,
    },
    tableContainer: {
        maxWidth: "fit-content",
        marginTop: 20,
    },
    tableHead: {
        backgroundColor: DesignConstants.COLORS.BACKGROUND_BLUE,
    },
    activityIndicatorContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    activityIndicator: {
        height: 8,
        width: 8,
        borderRadius: "50%",
    },
    morePopoverContainer: {
        flexDirection: "column",
        display: "flex",
    },
    moreButtonContainer: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        padding: 5,
    },
    marginLeft10: {
        marginLeft: 10,
    },
    addAccountContainer: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
    },
    addAccountDataContainer: {
        padding: 24,
        flex: 1,
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "flex-start",
        display: "flex",
        justifyContent: "center",
        maxWidth: 1000,
    },
    newAccountTextField: {
        marginLeft: 5,
        marginRight: 5,
    },
    datePickerContainer: {
        marginTop: 16,
        marginBottom: 8,
    },
    addAccountValidContainer: {
        alignItems: "center",
        justifyContent: "center",
        marginTop: 24,
        marginLeft: 5,
        marginRight: 5,
    },
    marginBottom24: {
        marginBottom: 24,
    },
    marginTop10: {
        marginTop: 10,
    },
    popoverInnerContainer: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        display: "flex",
    },
    buttonsContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 24,
        gap: 24,
    },
    downloadCodesContainer: {
        justifyContent: "center",
        alignItems: "center",
    },
};

export default AccountOverview;

